import { Button, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { addWorker } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { capitalizeFirstLetter } from "../../lib/tools/strings";


// todo(3) ajouter un toast si ca marche
export default function AddWorker({ setToast }) {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const { auth } = useContext(AuthContext);

    const reinitialize = () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setPassword("");
    }

    const handleAddWorker = () => {
        if (
            !firstname || firstname.length <= 2 ||
            !lastname || lastname.length <= 2 ||
            !email || email.length <= 2 ||
            !password || password.length <= 2
        ) {
            setToast({ text: "Echec, email ou mot de passe ou prénom ou nom imcomplet.", severity: "error" });
            return;
        }
        
        addWorker(firstname, lastname, email, password, auth.id, auth.company_id)
            .then(() => {
                reinitialize();
                setToast({ text: "Utilisateur créé.", severity: "success" });
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err)
            });
    };

    const customButtonStyle = {
        backgroundColor: 'rgb(36, 57, 138)',
        color: 'white',
    };

    if (!auth) {
        return null;
    }

    return (
        <Grid container spacing={2} style={{ padding: '10px' }}>
            <Grid item xs={12}>
                <h3 className="title">Ajouter un collaborateur</h3>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    label="Prénom"
                    value={firstname}
                    onChange={(ev) => setFirstname(capitalizeFirstLetter(ev.target.value))}
                    fullWidth
                    variant="outlined" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Nom"
                    value={lastname}
                    onChange={(ev) => setLastname(capitalizeFirstLetter(ev.target.value))}
                    fullWidth
                    variant="outlined" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value.toLowerCase())}
                    fullWidth
                    variant="outlined" />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Mots de passe"
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                    fullWidth
                    variant="outlined" />
            </Grid>

            <Grid item xs={12}>
                <Button onClick={handleAddWorker} variant="contained" style={customButtonStyle}>
                    Ajouter
                </Button>
            </Grid>
        </Grid>
    );
}
