import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";
import React from "react";

const ConfirmNotification = ({ text, confirmCallback, setConfirmation }) => {
    const handleYes = () => {
        confirmCallback();
        setConfirmation(null);
    };

    const handleNo = () => {
        setConfirmation(null);
    };

    return (
        <Dialog open={true}>
            {/* <DialogTitle>
                {" "}
                <Typography variant="h4">Lorem ipsum dolor sit amet consectetuer</Typography>
            </DialogTitle> */}
            <DialogContent>
                {/* <Typography variant="h6">
                    Are you sure you want to delete this user?
                </Typography> */}
                <Typography>
                    {text}
                </Typography>
                {/* <Typography variant="subtitle2">
                    You can't undo this operation
                </Typography> */}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleYes}>Oui</Button>
                <Button variant="contained" color="error" onClick={handleNo}>Non</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmNotification;