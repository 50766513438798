import { useContext, useEffect, useRef, useState } from 'react';
import Toggle from 'react-toggle';
import { getAllCount, getAllCountUser, getManagerLeads, getManagerLeadsCount, getUserLeads, getUserLeadsCount } from './api/axios';
import Header from './component/header';
import Lead from './component/lead';
import AuthContext from './context/AuthProvider';
import { LeadStatus, UserType } from './context/enums';

import './style/App.css';

import { useIntersection } from '@mantine/hooks';
import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import leftArrow from './images/left-arrow.svg';
import rightArrow from './images/right-arrow.svg';
import { useDifferentLists } from './lib/useDifferentLists';

function MainPage() {

  const { auth, listsIds } = useContext(AuthContext);

  const [input, setInput] = useState("")
  const [statut, setStatut] = useState("")
  const [pagelaod, setPageload] = useState(false)
  const [unique, setUnique] = useState(false)
  const [index, setIndex] = useState(0)
  const [totalCount, setTotalCount] = useState("")
  const [leads, setLeads] = useState([])
  const [statutCount, setStatutCount] = useState([])

  const [itemParPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const lastPostRef = useRef(null);
  const { ref, entry } = useIntersection({
    root: lastPostRef.current,
    threshold: 1
  });

  const [listIdIdx, getAccessibleListsIds, setListIdIdx, allLists] = useDifferentLists({ auth, listsIds });

  const fetchNextPage = () => {
    if (auth.user_type === UserType.MANAGER) {
      getManagerLeads(auth.id, getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, (currentPage + 1) * itemParPage)
        .then((res) => { if (res.data) { setLeads([...leads, ...res.data]) } });
    } else {
      getUserLeads(auth.id, getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, (currentPage + 1) * itemParPage)
        .then((res) => { if (res.data) { setLeads([...leads, ...res.data]) } });
    }
    setCurrentPage(currentPage + 1);
  }

  useEffect(() => {
    if (entry?.isIntersecting) fetchNextPage();
    // eslint-disable-next-line
  }, [entry])

  const setup = () => {
    if (!pagelaod) {
      if (auth.user_type === UserType.MANAGER) {

        getAllCount(auth.id).then((res) => { setStatutCount(res.data) });
        getManagerLeads(auth.id, getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, currentPage * itemParPage)
          .then((res) => { setLeads(res.data) });
        getManagerLeadsCount(auth.id).then((res) => setTotalCount(res.data));

      } else {

        getAllCountUser(auth.id).then((res) => { setStatutCount(res.data) });
        getUserLeads(auth.id, getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, currentPage * itemParPage)
          .then((res) => { setLeads(res.data) });
        getUserLeadsCount(auth.id).then((res) => setTotalCount(res.data));

      }
      setPageload(true);
    }
  }

  const handleSearchChange = (e) => {
    let searchField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setInput(searchField);
    if (auth.user_type === UserType.MANAGER) {
      getManagerLeads(auth.id, getAccessibleListsIds()[listIdIdx], searchField, statut, itemParPage, 0)
        .then((res) => { if (res) setLeads(res.data) });
    } else {
      getUserLeads(auth.id, getAccessibleListsIds()[listIdIdx], searchField, statut, itemParPage, 0)
        .then((res) => { if (res) setLeads(res.data) });
    }
  }

  const handleStatusChange = (e) => {
    let statusField = e.target.value;

    setCurrentPage(0);
    setLeads([]);
    setStatut(statusField);
    if (auth.user_type === UserType.MANAGER) {
      getManagerLeads(auth.id, getAccessibleListsIds()[listIdIdx], input, statusField, itemParPage, 0)
        .then((res) => { if (res) setLeads(res.data) });
    } else {
      getUserLeads(auth.id, getAccessibleListsIds()[listIdIdx], input, statusField, itemParPage, 0)
        .then((res) => { if (res) setLeads(res.data) });
    }
  }

  const handleListIdChange = (newViewIdx) => {
    let listId = getAccessibleListsIds()[newViewIdx];

    setCurrentPage(0);
    setLeads([]);
    setListIdIdx(newViewIdx);
    if (auth.user_type === UserType.MANAGER) {
      getManagerLeads(auth.id, listId, input, statut, itemParPage, 0)
        .then((res) => { if (res) setLeads(res.data) });
    } else {
      getUserLeads(auth.id, listId, input, statut, itemParPage, 0)
        .then((res) => { if (res) setLeads(res.data) });
    }
  }

  useEffect(() => {
    setup();
  });

  const getCentralComponent = () => {
    return <div>
      {unique && leads[index] &&
        <>
          <div className='controls'>
            {index !== 0 ? <img className='ctrl-icon' src={leftArrow} alt='left arrow' onClick={() => setIndex(index - 1)} /> : <div></div>}
            {index !== leads.length ? <img className='ctrl-icon' src={rightArrow} alt='right arrow' onClick={() => setIndex(index + 1)} /> : <div></div>}
          </div>
          <Lead key={index} lead={leads[index]} />
        </>}
      {!unique && <div className='leads'>
        {
          leads && leads?.map((lead) => {
            return <Lead key={lead.id} lead={lead} />
          })
        }
      </div>
      }
      {leads.length >= (itemParPage / 2) ? <div ref={ref}></div> : null}
    </div>;
  }

  const getTabsComponents = () => {
    return getAccessibleListsIds().map((listId, idx) => {
      let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];
      return <Tab key={listId} label={list.name} sx={{ color: '#090425', '&.Mui-selected': { color: '#090425' } }} />
    });
  }

  const getMainComponent = () => {
    if (!auth) {
      return null;
    }

    if (allLists === null) {
      return null;
    }

    if (getAccessibleListsIds().length < 2) {
      return getCentralComponent();
    }

    return <div>
      <Box style={{ paddingBottom: 30 }}>
        <Tabs
          value={listIdIdx}
          onChange={(event, newViewIdx) => {
            handleListIdChange(newViewIdx);
          }}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ "& .MuiTabs-indicator": { backgroundColor: '#090425' } }}
          aria-label="scrollable auto tabs example">
          {getTabsComponents()}
        </Tabs>
      </Box>
      {getCentralComponent()}
    </div>
  }

  return (
    <div className="App">
      <Header />
      <div className='body'>
        <div className='stat-title'>
          <h1 style={{ color: "#24398A" }}>{totalCount}</h1>
          <h1>{' leads'}</h1>
        </div>
        <div className='statut-list'>
          {statutCount?.map((s) => {
            return (<div className='statut-item'>
              <p className='statut-label'>{s.statut ? s.statut : 'Sans statut'}</p>
              <p className='statut-value'>{s.count_statut}</p>
            </div>);
          })}
        </div>
        <div className='filter_and_leads_container'>
          <div className='filter_and_card'>
            <div className='filter'>
              <div className='filter-names'>
                <h2 className='filter-tilte'>Rechercher</h2>
                <input className='filter-input' type='text' name='search'
                  id='search' placeholder='Nom, prénom, téléphone, email...'
                  onChange={(e) => handleSearchChange(e)} />
              </div>
              <div className='filter-names'>
                <h2 className='filter-tilte'>Statut</h2>
                <select id="filter-statut" defaultValue={statut ? statut : ''}
                  onChange={handleStatusChange}>
                  <option value="" >Choisir un statut</option>
                  {Object.values(LeadStatus).map((l, i) => {
                    return <option key={i} value={l}>{l}</option>
                  })
                  }
                </select>
              </div>
            </div>
            <div className='filter-card'>
              <Toggle
                id='cheese-status'
                defaultChecked={unique}
                onChange={() => setUnique(!unique)} />
              <p className='label-check'>One by one view</p>
            </div>
          </div>

          {getMainComponent()}
        </div>
      </div>
    </div>
  );
}

export default MainPage;