import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './context/AuthProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// PAGES
import Login from './Login.js';
import MainPage from './Main.js';
import Admin from './admin.js';
import { PrivateRoute } from './component/privateRoute.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute> <MainPage /> </PrivateRoute>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <PrivateRoute> <Admin /> </PrivateRoute>,
  },
  {
    path: "/collaborators",
    element: <PrivateRoute> <Admin /> </PrivateRoute>,
  }, // temporary
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);