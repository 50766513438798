import React, { useEffect, useState } from "react";

import { addLeads, getLeadsDepartementCount, getUserLeadsCount, removeLeads } from "../../api/axios";
import { Region } from "../../context/enums";
import addIcon from '../../images/add_icon.svg';
import rmIcon from '../../images/rm_icon.svg';
import "../../style/Collaborators.css";

export default function Collaborator(props) {

    const { user, setToast, setConfirmation, list } = props;

    const [leads, setLeads] = useState(0);
    const [nbleads, setNBLeads] = useState(100);
    const [region, setRegion] = useState('__default__');
    const [regionCount, setRegionCount] = useState(0);

    const isRegionDefined = () => {
        return region && region !== '__default__';
    }

    const updateUserLeadsCount = () => {
        getUserLeadsCount(user.id).then((res) => {
            if (res) setLeads(res.data);
        });
    }

    useEffect(() => {
        updateUserLeadsCount();
    }, [user.id, updateUserLeadsCount])

    // en cas d'erreur sur ces api et les autres, on met pas à jour l'UI
    useEffect(() => {
        setRegionCount(0);
        if (isRegionDefined()) {
            const regionFilter = region === "__all__" ? "__all__" : region.split(',');
            const json = JSON.stringify({ region: regionFilter, id: user.manager_id, list_id: list.id });
            getLeadsDepartementCount(json).then((res) => { if (res) setRegionCount(res.data) });
        }
    }, [region, user, list.id]);

    const addLeadsToCollaboratorImpl = (region, nbleads) => {
        const regionFilter = region === "__all__" ? "__all__" : region.split(',');
        const json = JSON.stringify({ region: regionFilter, len: nbleads, id: user.manager_id, list_id: list.id });

        addLeads(user.id, json).then((res) => {
            setToast({ text: "Leads attribués.", severity: "success" });
            updateUserLeadsCount();
            setRegionCount(0)
            setRegion('__default__');
        }).catch((err) => {
            console.log("leadd aded with failure");
            setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
            console.error(err);
        });
    }

    const addLeadsToCollaborator = () => {
        if (regionCount === 0) {
            setToast({ text: `Il n'y a aucun lead disponible.`, severity: "error" });
            return;
        }

        if (regionCount < nbleads) {
            setToast({ text: `Il n'y a que ${regionCount} leads disponibles.`, severity: "warning" });
        }
        setConfirmation({
            "text": "Etes-vous sur de vouloir attribuer des leads à cet utilisateur ?",
            "confirmCallback": () => addLeadsToCollaboratorImpl(region, nbleads)
        });
    }

    const removeAllLeadsFromCollaborator = () => {
        setConfirmation({
            "text": "Etes-vous sur de vouloir retirer tous les leads de cet utilisateur ?",
            "confirmCallback": () => {
                removeLeads(user.id).then(() => {
                    setToast({ text: "Leads retirés.", severity: "success" });
                    updateUserLeadsCount();
                }).catch((err) => {
                    console.error(err);
                    setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                });
            }
        });
    }

    return (
        <div className="collaborator">
            <div className="first-row">
                <p className="col-name">{user.firstname + " " + user.lastname}</p>
                <p className="number-of-leads">{leads} leads au total</p>
            </div>
            <div className="second-row">
                <div className="col-email">
                    <p className="email-label">Email</p>
                    <p className="email-value">{user.email}</p>
                </div>
                <div className="col-add-leads">
                    <div className="add-leads">
                        {isRegionDefined() && <p className="number-selected-leads">{regionCount} leads dispo</p>}
                        <select id="dropdown-region" value={region} onChange={(e) => setRegion(e.target.value)}>
                            <option value="__default__" disabled>Choisir une région</option>
                            <option value="__all__">Toute la France</option>
                            {list.split_by_department && Object.values(Region).map((r, i) => {
                                return <option key={i} value={r.CodePostal}>{r.display}</option>
                            })}
                        </select>
                    </div>
                    <div className="buttons">
                        {nbleads > 0 && <img id="icons" src={rmIcon} alt="rm Icon" onClick={() => setNBLeads(nbleads - 50)} />}
                        <input id="add-leads-btn" disabled={!isRegionDefined()} onClick={() => addLeadsToCollaborator()} type="button" value={"Ajouter " + nbleads + " leads de cette liste"} />
                        <img id="icons" src={addIcon} alt="Add leads Icon" onClick={() => setNBLeads(nbleads + 50)} />
                    </div>
                    <div className="buttons">
                        <input id="add-leads-btn" onClick={() => removeAllLeadsFromCollaborator()} type="button" value="Retirer les leads (toutes les listes)" />
                    </div>
                </div>
            </div>
            {/* <div className="action-btns">
                <input className="actions" type="button" value={"Reset password"}/>
                <input className="actions" type="button" value={"Supprimer"}/>
            </div> */}
        </div>
    );
}
