import axios from 'axios';
import { getBackendHost } from '../lib/config';


const URL = `${getBackendHost()}/v1/la/`
// const URL = 'http://51.178.83.139:8080/';

const axiosInstance = axios.create({
    baseURL: URL
});

export async function getLeads(companyId) {
    try {
        const json = JSON.stringify({ company_id: companyId })
        const res = await axiosInstance.put("leads", json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getManagerLeads(id, listId, search, status, limit, offset) {
    try {
        const json = JSON.stringify({ search: search, list_id: listId, status: status, limit: limit, offset: offset })
        const res = await axiosInstance.post("leads/manager/" + id, json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getManagerLeadsCount(id) {
    try {
        const res = await axiosInstance.get("leads/manager/count/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getManagerLeadsCountNotAssigned(id) {
    try {
        const res = await axiosInstance.get("leads/manager/count/notassigned/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getUserLeads(id, listId, search, status, limit, offset) {
    try {
        const json = JSON.stringify({ search: search, list_id: listId, status: status, limit: limit, offset: offset });
        const res = await axiosInstance.post("leads/users/" + id, json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getUserLeadsCount(id) {
    try {
        const res = await axiosInstance.get("leads/user/count/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getUser(id) {
    try {
        const res = await axiosInstance.get("users/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getUserEmail(email) {
    try {
        const json = JSON.stringify({ email: email })
        const res = await axiosInstance.post("users/email", json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getNRP(companyId) {
    try {
        const json = JSON.stringify({ company_id: companyId });
        const res = await axiosInstance.put("nrp", json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function clearNRP(id) {
    try {
        const res = await axiosInstance.get("leads/clear/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getNRPCount(id) {
    try {
        const res = await axiosInstance.get("nrp/count/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getAllCount(id) {
    try {
        const res = await axiosInstance.post("all/count/manager/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getAllCountUser(id) {
    try {
        const res = await axiosInstance.post("all/count/user/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getLeadsDepartementCount(json) {
    try {
        const res = await axiosInstance.post("leads/departement/count", json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function updateLead(id, json) {
    try {
        const res = await axiosInstance.post("leads/" + id, json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function addLeads(id, json) {
    return axiosInstance.post("leads/user/" + id, json,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

export async function removeLeads(id) {
    try {
        const res = await axiosInstance.post("remove/leads/" + id,);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function getCollaborators(id) {
    try {
        const res = await axiosInstance.get("collaborators/" + id);
        return (res);
    } catch (err) {
        console.error(err);
    }
}

export async function addWorker(firstname, lastname, email, password, managerId, companyId) {
    const json = JSON.stringify({
        firstname,
        lastname,
        email,
        password,
        user_type: "Collaborateur",
        manager_id: managerId,
        company_id: companyId
    });

    return axiosInstance.post("add/user", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function updatePassword(email, password) {
    const json = JSON.stringify({
        email,
        password
    });

    return axiosInstance.post("update/password", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllUsers(companyId) {
    const json = JSON.stringify({ company_id: companyId });

    return axiosInstance.put("users", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getLeadById(id) {
    const json = JSON.stringify({ id });

    return axiosInstance.put("get/lead/by/id", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function getAllLists() {
    return axiosInstance.get("get/all/list");
}

export async function getAllListsAccessibles(companyId) {
    const json = JSON.stringify({ company_id: companyId });

    return axiosInstance.put("get/list/accessibles", json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
