function getFileExtension(filename) {
    return filename.split(".").pop();
}

function renameStringIntoFileName(originalString) {
    return originalString.replace(/ /g, "_");
}

function removeWhitespace(inputString) {
    return inputString.replace(/\s/g, '');
}

function extractPattern(inputString, regex) {
    const match = inputString.match(regex);
    return match ? match[0] : null;
}

function countWordsOfMinSize(inputString, minSize) {
    return inputString.split(/\s+/).filter(word => word.trim().length >= minSize).length;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

module.exports = {
    getFileExtension,
    renameStringIntoFileName,
    removeWhitespace,
    extractPattern,
    countWordsOfMinSize,
    capitalizeFirstLetter,
};
