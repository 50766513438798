import { Box, Tab, Tabs } from '@mui/material';
import React, { useContext, useState } from 'react';
import Collaborators from './collaborators';
import AddWorker from './component/admin/add_worker';
import UpdatePassword from './component/admin/update_password';
import Header from './component/header';
import Toast from './component/ux/toast';
import AuthContext from "./context/AuthProvider";
import ConfirmNotification from './component/ux/confirmation';


const VIEWS_PROPERTIES = new Map();
VIEWS_PROPERTIES.set("collaborators", {
    buttonText: "Gestion des leads",
});
VIEWS_PROPERTIES.set("add_worker", {
    buttonText: "Ajouter un collaborateur",
});
VIEWS_PROPERTIES.set("update_password", {
    buttonText: "Mettre à jour mdp",
});

const VIEWS = [...VIEWS_PROPERTIES.keys()];


export default function Admin() {
    const [viewIdx, setViewIdx] = useState(0);
    const [toast, setToast] = useState(null);
    const [confirmation, setConfirmation] = useState(null);

    const { auth } = useContext(AuthContext);

    const getCentralComponent = () => {
        let viewName = VIEWS[viewIdx];
        if (viewName === "collaborators") {
            return <Collaborators setToast={setToast} setConfirmation={setConfirmation} />;
        } else if (viewName === "add_worker") {
            return <AddWorker setToast={setToast} />;
        } else if (viewName === "update_password") {
            return <UpdatePassword setToast={setToast} />;
        }

        return null;
    }

    const getTabsComponents = () => {
        return VIEWS.map((view, idx) => {
            let buttonText = VIEWS_PROPERTIES.get(view).buttonText;
            return <Tab key={view} label={buttonText} sx={{ color: '#090425', '&.Mui-selected': { color: '#090425' } }} />
        });
    }

    const getMainComponent = () => {
        if (!auth || auth.user_type !== "Manager") {
            return null;
        }

        return <div className="body">
            <Box>
                <Tabs
                    value={viewIdx}
                    onChange={(event, newView) => setViewIdx(newView)}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: '#090425' } }}
                    aria-label="scrollable auto tabs example">
                    {getTabsComponents()}
                </Tabs>
            </Box>
            {getCentralComponent()}
        </div>
    }

    return (
        <div className="collaborators">
            {toast !== null && <Toast text={toast.text} severity={toast.severity} setToast={setToast} />}
            {confirmation !== null&& <ConfirmNotification text={confirmation.text} confirmCallback={confirmation.confirmCallback} setConfirmation={setConfirmation} />}
            <Header />
            {getMainComponent()}
        </div>
    );
};
