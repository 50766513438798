import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import React, { useState } from 'react';


export default function Toast({ text, severity, setToast }) {
    /*
        severity in: success, info, warning, error
    */
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setToast(null);
    };

    return (
        <Snackbar open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity={severity} variant="filled">
                {text}
            </Alert>
        </Snackbar>
    );
}
