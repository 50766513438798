import { useEffect, useState } from "react";
import { getAllLists } from "../api/axios";


export function useDifferentLists({ auth, listsIds }) {
    const [listIdIdx, setListIdIdx] = useState(0);
    const [allLists, setAllLists] = useState(null);

    useEffect(() => {
        getAllLists().then((data) => {
            setAllLists(data.data);
        });
    }, []);

    const getAccessibleListsIds = () => {
        if (!auth) {
            return [1];
        }

        if (listsIds === undefined || listsIds === null) {
            return [1];
        }

        return listsIds;
    }

    return [
        listIdIdx,
        getAccessibleListsIds,
        setListIdIdx,
        allLists
    ];
}
