import React, { useContext, useEffect, useState } from "react";

import {
    clearNRP,
    getCollaborators,
    getManagerLeadsCount,
    getManagerLeadsCountNotAssigned,
    getNRPCount,
    getUser
} from "./api/axios";

import { Box, Tab, Tabs } from '@mui/material';
import Collaborator from "./component/admin/collaborator";
import AuthContext from "./context/AuthProvider";
import { UserType } from "./context/enums";
import { useDifferentLists } from './lib/useDifferentLists';

import "./style/Collaborators.css";

function Stat(props) {
    const { label, value } = props;

    return (
        <div className="stat">
            <p className="stat-label">{label}</p>
            <p className="stat-value">{value}</p>
        </div>);
}

// function Input(props) {
//     const { label, onChange } = props;

//     return (
//         <div className="input">
//             <p className="input-label">{label}</p>
//             <input className="input-value" placeholder={label} onChange={onChange} />
//         </div>);
// }

export default function Collaborators({ setToast, setConfirmation }) {

    const { auth, listsIds } = useContext(AuthContext);

    const [collaborators, setCollaborators] = useState([])
    const [manager, setManager] = useState("")
    const [nrpCount, setNrpCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [notAssignedCount, setNotAssignedCount] = useState(0)

    const isManager = auth.user_type === UserType.MANAGER;

    useEffect(() => {
        if (auth.user_type === UserType.MANAGER) {
            getCollaborators(auth.id).then((res) => { if (res) setCollaborators(res.data) })
            getManagerLeadsCount(auth.id).then((res) => { if (res) setTotalCount(res.data) })
            getManagerLeadsCountNotAssigned(auth.id).then((res) => { if (res) setNotAssignedCount(res.data) })
            getNRPCount(auth.id).then((res) => { if (res) setNrpCount(res.data) })
        } else if (auth.user_type === UserType.USER) {
            getUser(auth.manager_id).then((res) => { if (res) setManager(res.data) })
        }
    }, [auth]);

    const [listIdIdx, getAccessibleListsIds, setListIdIdx, allLists] = useDifferentLists({ auth, listsIds });

    const getCentralComponent = (list) => {
        return <div className="collaborators-list">
            {collaborators.map((c) => {
                return (<Collaborator key={c.id} user={c} setToast={setToast} setConfirmation={setConfirmation} list={list} />);
            })}
        </div>;
    }

    const getTabsComponents = () => {
        return getAccessibleListsIds().map((listId, idx) => {
            let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];
            return <Tab key={listId} label={list.name} sx={{ color: '#090425', '&.Mui-selected': { color: '#090425' } }} />
        });
    }

    const getMainComponent = (list) => {
        if (!auth) {
            return null;
        }

        if (allLists === null) {
            return null;
        }

        if (getAccessibleListsIds().length < 2) {
            return getCentralComponent(list);
        }

        return <div>
            <Box style={{ paddingBottom: 30 }}>
                <Tabs
                    value={listIdIdx}
                    onChange={(event, newViewIdx) => {
                        setListIdIdx(newViewIdx);
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ "& .MuiTabs-indicator": { backgroundColor: '#090425' } }}
                    aria-label="scrollable auto tabs example">
                    {getTabsComponents()}
                </Tabs>
            </Box>
            {getCentralComponent(list)}
        </div>
    }

    if (!auth) {
        return null;
    }

    if (allLists === null) {
        return null;
    }

    const listId = getAccessibleListsIds()[listIdIdx];
    let list = allLists?.filter((listDetails) => listDetails.id === listId)[0];

    const handCleanNRPClick = () => {
        clearNRP(auth.id)
            .then(() => getNRPCount(auth.id))
            .then((res) => {
                setToast({ text: "NRP retirés.", severity: "success" });
                setNrpCount(res.data);                
            }).catch((err) => {
                console.error(err);
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
            });
    }

    return (
        <>
            <h1 className="title">{isManager ? "Collaborateurs" : "Manager"}</h1>
            {!isManager && <div className="container-manager">
                <p className="title-manager">{manager.firstname + " " + manager.lastname}</p>
            </div>}
            {isManager && <div className="leads-stat">
                <Stat label={"Total leads"} value={totalCount} />
                <Stat label={"Leads non assigné"} value={notAssignedCount} />
            </div>}
            {isManager && <div className="nrp-col">
                <p className="nrps">{nrpCount + " NRP"}</p>
                <input className="actions" onClick={handCleanNRPClick} type="button" value={"Récupérer tous les NRP"} />
            </div>}
            {getMainComponent(list)}
        </>
    );
}
